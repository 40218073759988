/* eslint-disable prefer-template */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable react/self-closing-comp */
/* eslint-disable arrow-body-style */

import { useEffect, useState } from 'react';
import './transaction.css';
import * as React from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import RefreshIcon from '@mui/icons-material/Refresh';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import moment from 'moment-timezone';
import Skeleton from '@mui/material/Skeleton';
import { formatMinutesToMinutesAndSeconds } from '../../utils/formatDuration';
import Subcription from '../Subcription/Subcription';
import { Instance } from '../../config/Http';

const Transaction = () => {
  const [data, setData] = useState([]);
  const [posts, SetPosts] = useState(data);
  const [postPerPage, SetPostPerPage] = useState(10);
  const [currentPage, SetCurrentPage] = useState(1);
  const [lastDocId, setLastDocId] = useState('');
  const [totalLogs, setTotalLogs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState({});
  const [timeZone, setTimeZone] = useState('');
  const [numOfPages, setNumberOfPages] = useState(Math.ceil(totalLogs / postPerPage));
  const [numOfButtons, setNumOfButtons] = useState(Array.from({ length: numOfPages }, (_, i) => i + 1));

  const [pageItem, SetPageItem] = useState({
    start: 0,
    end: postPerPage,
  });

  const onPageChangeEvent = (start, end) => {
    SetPageItem({
      start: start,
      end: end,
    });
    fetchFilteredData();
  };

  const OnPerPostChangeEvent = (e) => {
    const pageSize = e.target.value;
    setNumberOfPages(Math.ceil(totalLogs / pageSize));
    setNumOfButtons(Array.from({ length: Math.ceil(totalLogs / pageSize) }, (_, i) => i + 1));
    SetPostPerPage(pageSize);
    SetCurrentPage(1);
  };

  const prevPageClick = () => {
    if (currentPage === 1) {
      SetCurrentPage(currentPage);
    } else {
      SetCurrentPage(currentPage - 1);
    }
  };

  const nextPageClick = () => {
    if (currentPage === numOfButtons.length) {
      SetCurrentPage(currentPage);
    } else {
      SetCurrentPage(currentPage + 1);
    }
  };

  const [arrOfCurrButtons, setArrOfCurrButtons] = useState([]);

  useEffect(() => {
    const getTimeZone = () => {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      setTimeZone(timeZone);
      fetchData();
    };

    getTimeZone();
  }, []);

  useEffect(() => {
    let tempNumberOfButtons = [...arrOfCurrButtons];

    let dotsInitial = '...';
    let dotsLeft = '... ';
    let dotsRight = ' ...';

    if (numOfButtons.length < 6) {
      tempNumberOfButtons = numOfButtons;
    } else if (currentPage >= 1 && currentPage <= 3) {
      tempNumberOfButtons = [1, 2, 3, 4, dotsInitial, numOfButtons.length];
    } else if (currentPage === 4) {
      const sliced = numOfButtons.slice(0, 5);
      tempNumberOfButtons = [...sliced, dotsInitial, numOfButtons.length];
    } else if (currentPage > 4 && currentPage < numOfButtons.length - 2) {
      // from 5 to 8 -> (10 - 2)
      const sliced1 = numOfButtons.slice(currentPage - 2, currentPage);
      // sliced1 (5-2, 5) -> [4,5]
      const sliced2 = numOfButtons.slice(currentPage, currentPage + 1);
      // sliced1 (5, 5+1) -> [6]
      tempNumberOfButtons = [1, dotsLeft, ...sliced1, ...sliced2, dotsRight, numOfButtons.length];
      // [1, '...', 4, 5, 6, '...', 10]
    } else if (currentPage > numOfButtons.length - 3) {
      // > 7
      const sliced = numOfButtons.slice(numOfButtons.length - 4);
      // slice(10-4)
      tempNumberOfButtons = [1, dotsLeft, ...sliced];
    } else if (currentPage === dotsInitial) {
      // [1, 2, 3, 4, "...", 10].length = 6 - 3  = 3
      // arrOfCurrButtons[3] = 4 + 1 = 5
      // or
      // [1, 2, 3, 4, 5, "...", 10].length = 7 - 3 = 4
      // [1, 2, 3, 4, 5, "...", 10][4] = 5 + 1 = 6
      SetCurrentPage(arrOfCurrButtons[arrOfCurrButtons.length - 3] + 1);
    } else if (currentPage === dotsRight) {
      SetCurrentPage(arrOfCurrButtons[3] + 2);
    } else if (currentPage === dotsLeft) {
      SetCurrentPage(arrOfCurrButtons[3] - 2);
    }

    setArrOfCurrButtons(tempNumberOfButtons);
    const value = currentPage * postPerPage;

    onPageChangeEvent(value - postPerPage, value);
  }, [currentPage, postPerPage, numOfPages]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const summaryResponse = await Instance.get(`/billing/outbound/logs-summary`);
      setSummary(summaryResponse.data.summary);

      const response = await Instance.get(`/billing/outbound/logs?timezone=${timeZone}&limit=${postPerPage}`);
      setLastDocId(response.data.lastDocId);
      setTotalLogs(response.data.totalLogs);
      setNumberOfPages(Math.ceil(response.data.totalLogs / postPerPage));
      setNumOfButtons(Array.from({ length: Math.ceil(response.data.totalLogs / postPerPage) }, (_, i) => i + 1));
      setData(response.data.logs);
      SetPosts(response.data.logs);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const fetchFilteredData = async () => {
    setLoading(true);
    try {
      const response = await Instance.get(
        `/billing/outbound/logs?timezone=${timeZone}&lastDocId=${lastDocId}&limit=${postPerPage}`
      );
      setLastDocId(response.data.lastDocId);
      setNumberOfPages(Math.ceil(response.data.totalLogs / postPerPage));
      setNumOfButtons(Array.from({ length: Math.ceil(response.data.totalLogs / postPerPage) }, (_, i) => i + 1));
      setData([...data, ...response.data.logs]);
      SetPosts([...data, ...response.data.logs]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const exportToExcel = () => {
    if (data.length === 0) return;

    const filteredData = data.map(({ id, updatedAt, callDuration, callTo, callAmount, walletBalance, callStatus }) => ({
      id,
      updatedAt,
      callDuration,
      callTo,
      callAmount,
      walletBalance,
      callStatus,
    })); // Example: Exporting only 'id' and 'name'

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      'ID,Call Timestamp,Called Number,Call Duration,Call Amount,Wallet Balance,Call Status\n' + // Column names
      filteredData
        .map((row) => [
          row.id,
          moment(row.updatedAt).format('DD-MM-YYYY HH:mm:ss'),
          row.callTo,
          formatMinutesToMinutesAndSeconds(row.callDuration),
          row.callAmount,
          row.walletBalance,
          row.callStatus,
        ])
        .join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'data.csv');
    document.body.appendChild(link);
    link.click();
  };
  const loader = [1, 2, 3, 4, 5];

  return (
    <>
      <div className="transaction-card">
        {summary && <Subcription summary={summary} />}
        <div className="">
          <>
            <div className="container-fluid mt-5 mb-5">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body p-0">
                      <div className="d-flex justify-content-between py-3">
                        <div>
                          <select
                            className="form-select ms-5"
                            aria-label="Default select example"
                            onChange={OnPerPostChangeEvent}
                          >
                            <option value="10" selected>
                              10
                            </option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <div className="button-export me-5">
                          <Stack direction="row" spacing={2}>
                            <Button variant="outlined" startIcon={<RefreshIcon />} onClick={fetchData}>
                              Refresh
                            </Button>
                            <Button variant="outlined" startIcon={<IosShareRoundedIcon />} onClick={exportToExcel}>
                              Export
                            </Button>
                          </Stack>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-text-small mb-0">
                          <thead className="thead-primary table-sorting">
                            <tr>
                              <th
                                className="text-muted "
                                style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}
                              >
                                Id
                              </th>
                              <th
                                className="text-muted "
                                style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}
                              >
                                Call Timestamp
                              </th>
                              <th
                                className="text-muted "
                                style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}
                              >
                                From Number
                              </th>
                              <th
                                className="text-muted "
                                style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}
                              >
                                Called Number
                              </th>
                              <th
                                className="text-muted "
                                style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}
                              >
                                Description
                              </th>
                              <th
                                className="text-muted "
                                style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}
                              >
                                Call Duration
                              </th>
                              <th
                                className="text-muted "
                                style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}
                              >
                                Call Amount
                              </th>
                              <th
                                className="text-muted "
                                style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}
                              >
                                Wallet Balance
                              </th>
                              <th
                                className="text-muted "
                                style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}
                              >
                                Call Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading
                              ? loader?.map((e) => {
                                  return (
                                    <>
                                      <tr style={{ width: '100%', height: '1vh' }}>
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '10px 10px 10px 10px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                          }}
                                        >
                                          {' '}
                                          <Skeleton variant="text" width={'100%'} height={20} />
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '10px 10px 10px 10px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Skeleton variant="text" width={'100%'} height={20} />
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '10px 10px 10px 10px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Skeleton variant="text" width={'100%'} height={20} />
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '10px 10px 10px 10px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Skeleton variant="text" width={'100%'} height={20} />
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '10px 10px 10px 10px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Skeleton variant="text" width={'100%'} height={20} />
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '10px 10px 10px 10px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Skeleton variant="text" width={'100%'} height={20} />
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '10px 10px 10px 10px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Skeleton variant="text" width={'100%'} height={20} />
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '10px 10px 10px 10px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Skeleton variant="text" width={'100%'} height={20} />
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '10px 10px 10px 10px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Skeleton variant="text" width={'100%'} height={20} />
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })
                              : posts?.slice(pageItem.start, pageItem.end).map((data, index) => {
                                  return (
                                    <tr key={data.id} style={{ width: '100%', height: '1vh' }} className="">
                                      <td
                                        className="text-success "
                                        style={{
                                          fontSize: '14px',
                                          padding: '10px 10px 10px 10px',
                                          fontWeight: '400',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {data.id}
                                      </td>
                                      <td
                                        className="text-dark"
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          padding: '10px 10px 10px 10px',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {moment.tz(data.updatedAt, timeZone).format('MMMM Do YYYY, h:mm:ss a')}
                                      </td>
                                      <td
                                        className="text-dark"
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          padding: '10px 10px 10px 10px',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {data.logType ? '-' : data.callFrom}
                                      </td>
                                      <td
                                        className="text-dark"
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          padding: '10px 10px 10px 10px',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {data.logType ? '-' : data.callTo}
                                      </td>
                                      <td
                                        className="text-dark"
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          padding: '10px 10px 10px 10px',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {data.logType ? data.description : data.callDescription}
                                      </td>
                                      <td
                                        className="text-dark"
                                        style={{
                                          fontSize: '14px',
                                          textAlign: 'center',
                                          padding: '10px 10px 10px 10px',
                                          fontWeight: '500',
                                        }}
                                      >
                                        {data.logType ? '-' : formatMinutesToMinutesAndSeconds(data.callDuration)}
                                      </td>
                                      <td
                                        className="text-danger"
                                        style={{
                                          fontSize: '14px',
                                          padding: '10px 10px 10px 10px',
                                          fontWeight: 'bolder',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {data.logType && data.logType.includes(`_RECHARGE`) ? (
                                          <span className="text-success">+${Number(data.price).toFixed(4)}</span>
                                        ) : (
                                          <span className="text-danger">-${Number(data.callAmount).toFixed(4)}</span>
                                        )}
                                      </td>
                                      <td
                                        className=" text-success"
                                        style={{
                                          fontSize: '14px',
                                          padding: '10px 10px 10px 10px',
                                          fontWeight: 'bolder',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {data.walletBalance > 0 ? (
                                          <span className="text-success">${Number(data.walletBalance).toFixed(2)}</span>
                                        ) : (
                                          <span className="text-danger">$-{Number(data.walletBalance).toFixed(2)}</span>
                                        )}
                                      </td>
                                      <td
                                        className="text-dark"
                                        style={{
                                          fontSize: '14px',
                                          textAlign: 'center',
                                          fontWeight: '500',
                                          padding: '10px 10px 10px 10px',
                                        }}
                                      >
                                        {data.logType ? '-' : data.callStatus}
                                      </td>
                                    </tr>
                                  );
                                })}
                          </tbody>
                        </table>
                      </div>
                      <div className="table-filter-info">
                        <div className="dt-pagination">
                          <ul className="dt-pagination-ul">
                            <li className={`dt-item ${currentPage === 1 ? 'disabled' : ''}`}>
                              <a className="dt-link" onClick={prevPageClick}>
                                Prev
                              </a>
                            </li>
                            {arrOfCurrButtons.map((data, index) => {
                              return (
                                <li
                                  key={index}
                                  className={`dt-item text-light ${currentPage === data ? 'active' : ''}`}
                                >
                                  <a className="dt-link text-light" onClick={() => SetCurrentPage(data)}>
                                    {data}
                                  </a>
                                </li>
                              );
                            })}
                            <li className={`dt-item ${currentPage === numOfButtons.length ? 'disabled' : ''}`}>
                              <a className="dt-link" onClick={nextPageClick}>
                                Next
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default Transaction;
