/* eslint-disable arrow-body-style */
import axios from 'axios';

// Create a new Axios instance
export const Instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER_URL}/api/v1/users`,
});

Instance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

Instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && error.response.data.error === 'TokenExpiredError') {
      // If the error is due to token expiration
      try {
        const accessToken = await refreshToken(); // Refresh the token
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return Instance(originalRequest); // Retry the original request with the new token
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

// Function to refresh token
const refreshToken = async () => {
  // Call your refresh token endpoint here
  const response = await Instance.post('/auth/refreshToken', {
    // Send any necessary data for refreshing the token
    refreshToken: localStorage.getItem('refreshToken'),
  });

  // Assuming your API returns a new access token
  const newAccessToken = response.data.accessToken;
  const newRefreshToken = response.data.refreshToken;

  // Update your app state with the new access token
  localStorage.setItem('accessToken', newAccessToken);
  localStorage.setItem('refreshToken', newRefreshToken);

  // Return the new access token
  return newAccessToken;
};
