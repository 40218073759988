/* eslint-disable no-var */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable arrow-body-style */
// import React from 'react'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import AddIcon from '@mui/icons-material/Add';
import { Delete } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import ShareIcon from '@mui/icons-material/Share';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Instance } from 'src/config/Http';
import swal from 'sweetalert2';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import MySwiper from '../MySwiper/MySwiper';
import './Billing.css';
import Histroy from './Histroy';
// import CreditCardForm from './CreditForm';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const Billing = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [cards, setCards] = useState([]);
  const [billingHistory, setBillingHistory] = useState([]);
  const [balance, setBalance] = useState(0);
  const [credits, setCredits] = useState(0);
  const [tgBalance, setTgBalance] = useState(0);
  const [tgCredits, setTgCredits] = useState(0);
  const [customerId, setCustomerId] = useState('');
  const [amount, setAmount] = useState('');
  const [cardholderName, setCardholderName] = useState('');
  const [connectedAccount, setConnectedAccount] = useState(null);
  const [isPrimary, setIsPrimary] = useState(true);
  const [autoRechargeWith, setAutoRechargeWith] = useState(25);
  const [balanceLowerThan, setBalanceLowerThan] = useState(10);
  const [tgAutoRechargeWith, setTgAutoRechargeWith] = useState(25);
  const [tgBalanceLowerThan, setTgBalanceLowerThan] = useState(10);
  const [loader, setloader] = useState(false);
  const [addcardloader, setAddCardloader] = useState(false);
  const [billingloader, setBillingloader] = useState(false);
  const [creditloader, setCreditloader] = useState(false);
  const [index, setIndex] = useState(1);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const stripe = useStripe();
  const elements = useElements();
  const query = useQuery();
  const email = query.get('email');

  const [errorMessage, setErrorMessage] = useState(null);

  const Navigate = useNavigate();
  const handleLogout = () => {
    const email = localStorage.getItem('email');
    const locationId = localStorage.getItem('locationId');
    localStorage.clear('accessToken');
    Navigate(`/login/${email}?locationId=${locationId}`);
  };

  const handleSubmit = async (event) => {
    const { error: submitError } = await elements.submit();

    if (submitError) {
      setErrorMessage(submitError.message);
    } else if (!cardholderName) {
      swal.fire({
        title: 'Card name required.',
        icon: 'error',
      });
    } else {
      setAddCardloader(true);

      const card = elements.getElement(CardElement);
      await stripe
        .createPaymentMethod({
          type: 'card',
          card,
          billing_details: {
            name: cardholderName,
          },
        })
        .then((res) => {
          const { error } = res;

          if (error) {
            setAddCardloader(false);
            swal.fire({
              title: "Card couldn't be added",
              text: error.message,
              icon: 'error',
            });
          } else {
            Instance.post('billing/addCard', { paymentMethodId: res.paymentMethod.id, isPrimary })
              .then(() => {
                setAddCardloader(false);
                getCards();
                setCardholderName('');
                handleClose();
              })
              .catch((error2) => {
                setAddCardloader(false);
                swal.fire({
                  title: "Card couldn't be added",
                  text: error2.response.data.message,
                  icon: 'error',
                });
              });
          }
        })
        .catch((err) => {
          setAddCardloader(false);
          swal.fire({
            title: "Card couldn't be added",
            text: err.description,
            icon: 'error',
          });
        });
    }
  };

  useEffect(() => {
    getCards();
    getBalance();
    getBillingHistory();
  }, []);

  const getBillingHistory = () => {
    setBillingloader(true);

    Instance.get('billing/getBillingHistory')
      .then((res) => {
        if (res.data.status) {
          setBillingloader(false);

          setBillingHistory([...res.data.data]);
        }
      })
      .catch(() => {
        setBillingloader(false);
      });
  };

  const getCards = () => {
    setloader(true);

    Instance.get('billing/getCards')
      .then((res) => {
        if (res.data.status) {
          setloader(false);

          setCards([...res.data.cards]);
        }
      })
      .catch(() => {
        setloader(false);
      });
  };

  const getBalance = () => {
    setCreditloader(true);

    Instance.get(`/billing/profile`)
      .then((res) => {
        if (res.data) {
          setCreditloader(false);
          setBalance(res.data.balance);
          setTgBalance(res.data.tgBalance);
          setTgCredits(res.data.tgCredits);
          setCredits(res.data.credits);
          setCustomerId(res.data.StripeCustomer.id);
          setConnectedAccount(res.data.StripeConnectedAccount);
          setBalanceLowerThan(res.data.balanceLowerThan);
          setTgBalanceLowerThan(res.data.tgBalanceLowerThan);
          setAutoRechargeWith(res.data.autoRechargeWith);
          setTgAutoRechargeWith(res.data.tgAutoRechargeWith);
        }
      })
      .catch(() => {
        setCreditloader(false);
      });
  };

  const handleAddBalance = () => {
    if (!amount) {
      swal.fire({
        title: 'Amount is required.',
        icon: 'error',
      });
    } else if (Number(amount) <= 0) {
      swal.fire({
        title: 'Amount should be greater than 0.',
        icon: 'error',
      });
    } else if (cards.length === 0) {
      swal.fire({
        title: 'You have to add card before adding a balance',
        icon: 'error',
      });
    } else {
      setCreditloader(true);
      Instance.post('billing/add-funds', { amount, paymentMethodId: cards[0]?.id })
        .then(async (res) => {
          setCreditloader(true);

          if (res.data) {
            setCreditloader(false);
            getBalance();
            handleClose1();
          }
        })
        .catch((error) => {
          setCreditloader(false);
          swal.fire({
            title: 'Amount could not be added',
            icon: 'error',
            text: error.response.data.message,
          });
        });
    }
  };

  const handleAddTgBalance = () => {
    if (!amount) {
      swal.fire({
        title: 'Amount is required.',
        icon: 'error',
      });
    } else if (Number(amount) <= 0) {
      swal.fire({
        title: 'Amount should be greater than 0.',
        icon: 'error',
      });
    } else if (cards.length === 0) {
      swal.fire({
        title: 'You have to add card before adding a balance',
        icon: 'error',
      });
    } else {
      setCreditloader(true);
      Instance.post('billing/add-tg-funds', { amount, paymentMethodId: cards[0]?.id })
        .then(async (res) => {
          setCreditloader(true);

          if (res.data) {
            setCreditloader(false);
            getBalance();
            handleClose2();
          }
        })
        .catch((error) => {
          setCreditloader(false);
          swal.fire({
            title: 'Amount could not be added',
            icon: 'error',
            text: error.response.data.message,
          });
        });
    }
  };

  const handleAutoRechargeChange = (e) => {
    setCreditloader(true);

    Instance.post('billing/updateAutoRechargeWith', { amount: e.target.value })
      .then(async (res) => {
        if (res.data) {
          setCreditloader(false);

          // await stripe.confirmCardPayment(res.data.secret);
          getBalance();
          handleClose1();
          getBillingHistory();
        }
      })
      .catch(() => {
        setCreditloader(false);
      });
  };

  const handleTgAutoRechargeChange = (e) => {
    setCreditloader(true);

    Instance.post('billing/updateTgAutoRechargeWith', { amount: e.target.value })
      .then(async (res) => {
        if (res.data) {
          setCreditloader(false);

          // await stripe.confirmCardPayment(res.data.secret);
          getBalance();
          handleClose2();
          getBillingHistory();
        }
      })
      .catch(() => {
        setCreditloader(false);
      });
  };

  const handleBalancelowerthenChange = (e) => {
    setCreditloader(true);

    Instance.post('billing/updateBalanceLowerThan', { amount: e.target.value })
      .then(async (res) => {
        if (res.data) {
          setCreditloader(false);

          // await stripe.confirmCardPayment(res.data.secret);
          getBalance();
          handleClose1();
        }
      })
      .catch(() => {
        setCreditloader(false);
      });
  };

  const handleTgBalancelowerthenChange = (e) => {
    setCreditloader(true);

    Instance.post('billing/updateTgBalanceLowerThan', { amount: e.target.value })
      .then(async (res) => {
        if (res.data) {
          setCreditloader(false);

          // await stripe.confirmCardPayment(res.data.secret);
          getBalance();
          handleClose2();
        }
      })
      .catch(() => {
        setCreditloader(false);
      });
  };

  const handleMakeCardPrimary = (id) => {
    // setloader(true);
    Instance.post('billing/makeCardPrimary', { paymentMethodId: id })
      .then(async (res) => {
        if (res.data) {
          getCards();
        }
      })
      .catch(() => {
        // setloader(false);
      });
  };

  const handleRemoveCard = () => {
    swal
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      })
      .then((result) => {
        if (result.isConfirmed) {
          setloader(true);
          Instance.post('billing/removeCard', { paymentMethodId: cards?.[index - 1]?.id })
            .then(async (res) => {
              if (res.data) {
                setloader(false);
                swal.fire({
                  title: 'Deleted!',
                  text: 'Your file has been deleted.',
                  icon: 'success',
                });
                getCards();
              }
            })
            .catch(() => {
              setloader(false);
            });
        }
      });
  };

  return (
    <>
      <section className="px-0">
        <div className="">
          <div className="d-flex justify-content-between py-4">
            <div className="">
              <h5 className="">Voice AI Usage</h5>
            </div>
            <div className="">
              <Button
                variant="filled"
                className="px-4 rounded"
                fullWidth
                color="primary"
                sx={{
                  backgroundColor: '#63b3ed',
                  color: 'white',
                  borderRadius: 0,
                  ':hover': { backgroundColor: 'black' },
                }}
                startIcon={<PowerSettingsNewIcon />}
                onClick={() => handleLogout()}
              >
                Logout
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="card rounded p-4">
                <h6 className="card-title">Payment methods</h6>

                {loader ? (
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <CircularProgress style={{ color: '#000', fontSize: '24px' }} />
                  </div>
                ) : (
                  <div className="card-body p-0 py-4">
                    <div className="row">
                      <div className="col-md-9">
                        {cards.length > 0 ? (
                          <div className="swiper-container">
                            <MySwiper
                              index={index}
                              setIndex={setIndex}
                              cards={cards}
                              handleMakeCardPrimary={handleMakeCardPrimary}
                            />
                          </div>
                        ) : (
                          <div>
                            No cards available in your account. Please add a new card to add balance to your Voice AI
                            wallet.
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 px-1">
                        <div className="button-export mx-1">
                          <Button
                            variant="filled"
                            className="px-1 rounded"
                            fullWidth
                            color="primary"
                            sx={{ backgroundColor: '#63b3ed', color: 'white', ':hover': { background: 'black' } }}
                            startIcon={<AddIcon />}
                            onClick={handleShow}
                          >
                            Add Card
                          </Button>
                        </div>
                        {cards.length > 0 && (
                          <div className="button-export mt-2">
                            <div className="button-export mx-1">
                              <Button
                                variant="outline"
                                className="px-1 rounded"
                                fullWidth
                                color="inherit"
                                startIcon={<Delete />}
                                sx={{
                                  border: '1px solid red',
                                  color: 'red',
                                }}
                                onClick={handleRemoveCard}
                              >
                                Remove Card
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="card my-3 rounded">
                <div className="d-flex justify-content-between p-4 pb-0">
                  <h6 className="card-title center-block">Voice AI Wallet Balance</h6>
                  {cards.length > 0 && (
                    <Button
                      variant="filled"
                      className="px-4 rounded"
                      color="primary"
                      sx={{ backgroundColor: '#63b3ed', color: 'white', ':hover': { background: 'black' } }}
                      startIcon={<AddIcon />}
                      onClick={handleShow1}
                    >
                      Add Balance
                    </Button>
                  )}
                </div>

                <div className="card-body p-4">
                  <div style={{ background: '#c6f6d5', borderRadius: '6px' }} className="px-4 py-3">
                    {creditloader ? (
                      <div className="d-flex justify-content-center align-items-cener">
                        <CircularProgress style={{ color: '#000', fontSize: '24px' }} />
                      </div>
                    ) : (
                      <>
                        <div className="d-flex justify-content-between">
                          <h6>Total Balance</h6>
                          <h6 className="fw-bolder">${Number(balance + credits).toFixed(2)}</h6>
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                          <p>Balance:</p>
                          <p className="fw-bolder"> ${Number(balance).toFixed(2)}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p>Complimentary Credits:</p>
                          <p className="fw-bolder">${Number(credits).toFixed(2)}</p>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="card-header mt-2 px-0">
                    <button className="fw-light text-dark fst-italic border-0 bg-white" onClick={getBalance}>
                      <i>
                        <RefreshIcon fontSize="small" sx={{ color: 'gray' }} />
                      </i>
                      Refresh
                    </button>
                    <div className="">
                      <Link to={'/dashboard/voice-ai-daily-stats'} style={{ cursor: 'pointer' }}>
                        <p className="fw-light text-primary">See Daily Stats</p>
                      </Link>
                      <Link to={'/dashboard/transaction'} style={{ cursor: 'pointer' }}>
                        <p className="fw-light text-primary">See Transactions</p>
                      </Link>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mt-4">
                    <div className="fs-6 d-flex justify-content-center align-items-center">Auto recharge with</div>
                    <div className="d-flex align-items-center">
                      $
                      <select
                        value={autoRechargeWith}
                        onChange={handleAutoRechargeChange}
                        className="form-select ms-2"
                        aria-label="Default select example"
                        defaultValue={autoRechargeWith}
                      >
                        <option value={'10'}>10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mt-2">
                    <div className="fs-6 d-flex justify-content-center align-items-center">When balance lower than</div>
                    <div className="d-flex align-items-center">
                      $
                      <select
                        value={balanceLowerThan}
                        onChange={handleBalancelowerthenChange}
                        className="form-select ms-2"
                        aria-label="Default select example"
                        defaultValue={balanceLowerThan}
                      >
                        <option value={'0'}>0</option>
                        <option value={'10'}>10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card my-3 rounded">
                <div className="d-flex justify-content-between p-4 pb-0">
                  <h6 className="card-title center-block">Hybrid Wallet Balance</h6>
                  {cards.length > 0 && (
                    <Button
                      variant="filled"
                      className="px-4 rounded"
                      color="primary"
                      sx={{ backgroundColor: '#63b3ed', color: 'white', ':hover': { background: 'black' } }}
                      startIcon={<AddIcon />}
                      onClick={handleShow2}
                    >
                      Add Balance
                    </Button>
                  )}
                </div>

                <div className="card-body p-4">
                  <div style={{ background: '#c6f6d5', borderRadius: '6px' }} className="px-4 py-3">
                    {creditloader ? (
                      <div className="d-flex justify-content-center align-items-cener">
                        <CircularProgress style={{ color: '#000', fontSize: '24px' }} />
                      </div>
                    ) : (
                      <>
                        <div className="d-flex justify-content-between">
                          <h6>Total Balance</h6>
                          <h6 className="fw-bolder">${Number(tgBalance + tgCredits).toFixed(2)}</h6>
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                          <p>Balance:</p>
                          <p className="fw-bolder"> ${Number(tgBalance).toFixed(2)}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p>Complimentary Credits:</p>
                          <p className="fw-bolder">${Number(tgCredits).toFixed(2)}</p>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="card-header mt-2 px-0">
                    <button className="fw-light text-dark fst-italic border-0 bg-white" onClick={getBalance}>
                      <i>
                        <RefreshIcon fontSize="small" sx={{ color: 'gray' }} />
                      </i>
                      Refresh
                    </button>
                    <Link to={'/dashboard/tg-transaction'} style={{ cursor: 'pointer' }}>
                      <p className="fw-light text-primary">See Transactions</p>
                    </Link>
                  </div>

                  <div className="d-flex justify-content-between mt-4">
                    <div className="fs-6 d-flex justify-content-center align-items-center">Auto recharge with</div>
                    <div className="d-flex align-items-center">
                      $
                      <select
                        value={tgAutoRechargeWith}
                        onChange={handleTgAutoRechargeChange}
                        className="form-select ms-2"
                        aria-label="Default select example"
                        defaultValue={tgAutoRechargeWith}
                      >
                        <option value={'10'}>10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mt-2">
                    <div className="fs-6 d-flex justify-content-center align-items-center">When balance lower than</div>
                    <div className="d-flex align-items-center">
                      $
                      <select
                        value={tgBalanceLowerThan}
                        onChange={handleTgBalancelowerthenChange}
                        className="form-select ms-2"
                        aria-label="Default select example"
                        defaultValue={tgBalanceLowerThan}
                      >
                        <option value={'0'}>0</option>
                        <option value={'10'}>10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card scroll py-2 rounded">
                <div className="card-header border-0">
                  <h6 className="card-title">Billing History</h6>
                  <button onClick={getBillingHistory} className="fw-light text-dark fst-italic border-0 bg-white">
                    <i>
                      <RefreshIcon fontSize="small" sx={{ color: 'gray' }} />
                    </i>
                    Refresh
                  </button>
                </div>
                <div className="card-body p-0">
                  {billingloader ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <CircularProgress style={{ color: '#000', fontSize: '24px' }} />
                    </div>
                  ) : billingHistory.length ? (
                    billingHistory.map((val) => <Histroy {...val} />)
                  ) : (
                    <div className="p-4">No transactions found</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose} centered keyboard={false}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="fw-bold ">Add Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="">
              <TextField
                sx={{
                  '.MuiInput-input': {
                    color: '#000 !important',
                  },
                }}
                onChange={(e) => setCardholderName(e.target.value)}
                fullWidth
                id="outlined-basic"
                label="Enter name on card"
                variant="outlined"
                size="medium"
              />
              <div
                className="border rounded px-2 py-3 mt-3 justify-content-center align-items-center"
                style={{ height: '56px' }}
              >
                <CardElement
                  options={{
                    hidePostalCode: true,
                    disableLink: true,
                  }}
                />
                {errorMessage && <div>{errorMessage}</div>}
              </div>
              <div className="d-flex justify-content-start align-items-center mt-2">
                <FormControlLabel
                  className="px-2"
                  control={<Android12Switch defaultChecked />}
                  onChange={(e) => setIsPrimary(e.target.checked)}
                  checked={isPrimary}
                  label="Make this card primary"
                />
              </div>

              <h6 className="my-2 px-1" style={{ fontSize: '12px' }}>
                By providing your card information, you allow Agent Suite to charge your card for future payments in
                accordance with their terms.
              </h6>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={(e) => {
              handleSubmit();
            }}
            className="text-white"
            color="success"
          >
            Add Card{' '}
            {addcardloader && (
              <CircularProgress style={{ color: '#fff', width: '20px', height: '20px', marginLeft: '5px' }} />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1} centered keyboard={false}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="fw-bold ">Add money to Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">Enter Amount</InputLabel>
              <Input
                id="standard-adornment-amount"
                value={amount}
                // color='#000'
                sx={{
                  '.MuiInput-input': {
                    color: '#000 !important',
                  },
                }}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                onChange={(e) => setAmount(e.target.value)}
              />
            </FormControl>
            <h6 className="my-4" style={{ fontSize: '12px' }}>
              Current Balance: <strong>${(balance + credits).toFixed(2)}</strong>
            </h6>
            <h6 className="my-4" style={{ fontSize: '12px' }}>
              {cards?.[0]?.isPrimary ? (
                <span>
                  Your <strong>{cards?.[0]?.brand}</strong> card ending in <strong>{cards?.[0]?.last4}</strong> will be
                  charged.
                </span>
              ) : (
                'Add a new card or make your card primary'
              )}
            </h6>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={(e) => {
              handleAddBalance();
            }}
            className="z-n1 text-white"
            color="success"
            disabled={!cards?.[0]?.isPrimary}
          >
            Proceed{' '}
            {creditloader && (
              <CircularProgress style={{ color: '#fff', width: '20px', height: '20px', marginLeft: '5px' }} />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose1} centered keyboard={false}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="fw-bold ">Add money to Hybrid Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">Enter Amount</InputLabel>
              <Input
                id="standard-adornment-amount"
                value={amount}
                // color='#000'
                sx={{
                  '.MuiInput-input': {
                    color: '#000 !important',
                  },
                }}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                onChange={(e) => setAmount(e.target.value)}
              />
            </FormControl>
            <h6 className="my-4" style={{ fontSize: '12px' }}>
              Current Balance: <strong>${(tgBalance + tgCredits).toFixed(2)}</strong>
            </h6>
            <h6 className="my-4" style={{ fontSize: '12px' }}>
              {cards?.[0]?.isPrimary ? (
                <span>
                  Your <strong>{cards?.[0]?.brand}</strong> card ending in <strong>{cards?.[0]?.last4}</strong> will be
                  charged.
                </span>
              ) : (
                'Add a new card or make your card primary'
              )}
            </h6>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={(e) => {
              handleAddTgBalance();
            }}
            className="z-n1 text-white"
            color="success"
            disabled={!cards?.[0]?.isPrimary}
          >
            Proceed{' '}
            {creditloader && (
              <CircularProgress style={{ color: '#fff', width: '20px', height: '20px', marginLeft: '5px' }} />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Billing;
